import { useCallback, useEffect, useRef, useState } from 'react';
import { smoothScroll } from '../helpers';

// hooks/useCarouselScroll.ts
interface UseCarouselScrollProps {
  itemsCount: number;
  itemWidth: number;
  padding?: number;
}

const SCROLL_DURATION = 400;
const ITEM_PADDING = 32;

const useCarouselScroll = ({
  itemsCount,
  itemWidth,
  padding = ITEM_PADDING,
}: UseCarouselScrollProps) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [isFullyScrolled, setIsFullyScrolled] = useState(false);
  const [canAllItemsFit, setCanAllItemsFit] = useState(false);

  const updateScrollStatus = useCallback(
    (current: number) => {
      if (!carouselRef.current) return;

      const { scrollWidth, offsetWidth } = carouselRef.current;
      setScrollAmount(current);
      setIsFullyScrolled(current >= scrollWidth - offsetWidth);
      setCanAllItemsFit(offsetWidth >= itemWidth * itemsCount + padding);
    },
    [itemWidth, itemsCount, padding],
  );

  const scrollToIndex = useCallback(
    (index: number) => {
      if (!carouselRef.current) return;

      const { scrollWidth } = carouselRef.current;
      const targetScroll = index * (scrollWidth / itemsCount);

      if (targetScroll > 0) {
        smoothScroll(
          carouselRef.current,
          carouselRef.current.scrollLeft,
          targetScroll,
          SCROLL_DURATION,
          updateScrollStatus,
        );
      }
    },
    [itemsCount, updateScrollStatus],
  );

  const scrollPrev = useCallback(() => {
    if (!carouselRef.current) return;

    const { scrollLeft, scrollWidth } = carouselRef.current;
    const targetScroll = Math.max(scrollLeft - scrollWidth / itemsCount, 0);

    smoothScroll(
      carouselRef.current,
      scrollLeft,
      targetScroll,
      SCROLL_DURATION,
      updateScrollStatus,
    );
  }, [itemsCount, updateScrollStatus]);

  const scrollNext = useCallback(() => {
    if (!carouselRef.current) return;

    const { scrollLeft, scrollWidth, offsetWidth } = carouselRef.current;
    const targetScroll = Math.min(scrollLeft + scrollWidth / itemsCount, scrollWidth - offsetWidth);

    smoothScroll(
      carouselRef.current,
      scrollLeft,
      targetScroll,
      SCROLL_DURATION,
      updateScrollStatus,
    );
  }, [itemsCount, updateScrollStatus]);

  return {
    carouselRef,
    scrollAmount,
    isFullyScrolled,
    canAllItemsFit,
    scrollToIndex,
    scrollPrev,
    scrollNext,
  };
};
export default useCarouselScroll;
