import { Box, Stack } from '@mui/material';
import LAYOUT_DEFAULTS from '../../../layout/utils';

const Video = () => (
  <Stack
    position="relative"
    alignItems="center"
    sx={{
      ...LAYOUT_DEFAULTS,
    }}
  >
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        paddingBottom: 'calc(100% * 9 / 16)',
        height: 0,
        overflow: 'hidden',
        background: '#000',
        ml: 'auto',
        mr: 'auto',
        borderRadius: '20px',
      }}
    >
      <iframe
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '20px',
        }}
        src="https://www.youtube.com/embed/WJXY4XDwn5o?si=xW1jEkeRWgpoQJ4d&controls=1&autoplay=1&mute=1&loop=1&iv_load_policy=3&modestbranding=1&playlist=WJXY4XDwn5o"
        title="YouTube video player"
        frameBorder={0}
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </Box>
  </Stack>
);

export default Video;
