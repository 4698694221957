import { Box, Stack, Typography } from '@mui/material';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import CustomButton from '../../../../components/button/CustomButton';
import EbookImg from '../../../../assets/img/public-pages/innovative-agency-growth-strategies.png';
import CheckedItem from '../../Pricing/components/CheckedItem';

const SponaInfobip = () => (
  <Stack
    spacing={12.5}
    direction="column"
    alignItems="center"
    sx={{
      marginTop: `${PUBLIC_APPBAR_HEIGHT + 80}px`,
      marginBottom: { xs: '10rem' },
      overflow: 'hidden',
      mx: 'auto',
      maxWidth: '1440px',
      width: '100%',
      px: { xs: '16px', md: '32px', gr: '80px' },
    }}
  >
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ px: '138px' }}
      gap={0}
    >
      <Typography variant="publicBody3Regular" color="primaryLight.500" sx={{ mb: 1 }}>
        Free Download
      </Typography>
      <Typography
        variant="publicHeading2"
        textAlign="center"
        sx={{ mb: 3, fontSize: { xs: '3rem', sm: '4rem' } }}
      >
        Innovative Agency Growth Strategies
      </Typography>
      <Typography variant="publicBody2Regular" textAlign="center" sx={{ mb: 5 }}>
        This eBook is your guide to staying ahead in today’s digital dialogue. Learn how
        conversational marketing, AI-powered tools, and omnichannel strategies can help your agency
        drive deeper customer connections, boost loyalty, and create new revenue streams. Are you
        ready to change the way you engage and grow? Download now to thrive in the era of real-time
        communication.
      </Typography>
      <CustomButton
        variant="primary"
        sx={{
          py: { xs: '1.125rem' },
          width: 'fit-content',
        }}
        href="https://tdc-production.ams3.cdn.digitaloceanspaces.com/_public/ebooks/innovative_agency_growth_strategies.pdf"
      >
        Download for free
      </CustomButton>
    </Stack>

    <Stack
      direction={{ xs: 'column', bg: 'row' }}
      alignItems="center"
      spacing={10}
      justifyContent="space-between"
      sx={{ width: '100%' }}
      overflow="hidden"
    >
      <Box
        component="img"
        src={EbookImg}
        alt="Innovative Agency Growth Strategies e-book cover"
        sx={{
          maxWidth: { xs: '471px', sm: '628px' }, //470 628
          maxHeight: { xs: '425px', sm: '566px' }, //425px 566
          borderRadius: '12px',
        }}
      />
      <Stack direction="column" gap={2} sx={{ mt: { xs: '4rem', bg: 0 } }}>
        <Typography variant="publicHeading4" sx={{ mb: '1rem' }}>
          You will find out:
        </Typography>
        <CheckedItem title="Transformative strategies for client engagement" />
        <CheckedItem title="Trends and case studies across industries" />
        <CheckedItem title="Tools for seamless, personalized experiences" />
      </Stack>
    </Stack>
  </Stack>
);

export default SponaInfobip;
