import { Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { ReactComponent as ErrorAlertIcon } from '../../../../../../assets/icons/layout-icons/ErrorAlertIcon.svg';
import { ReactComponent as CancelIcon } from '../../../../../../assets/icons/project-icons/CancelCircleIcon.svg';
import { ReactComponent as TrashIcon } from '../../../../../../assets/icons/layout-icons/TrashIcon.svg';
import ConfirmModal from '../../../../../../components/confirm-modal/ConfirmModal';
import { primaryLight, secondaryBlue, secondaryPink } from '../../../../../../color';

interface DeleteTaskFileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type: 'delete' | 'error';
  onConfirm?: () => void;
  isLoading?: boolean;
}

const DeleteTaskFileModal = ({
  isOpen,
  setIsOpen,
  type,
  onConfirm,
  isLoading,
}: DeleteTaskFileModalProps) => {
  const modalsContent = {
    delete: {
      title: 'Delete file',
      text: 'Are you sure that you want to delete this file?',
      icon: <TrashIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />,
      wrapperBackgroundColor: primaryLight[100],
    },
    error: {
      title: 'Error',
      text: 'Something went wrong. Please try again later.',
      icon: <ErrorAlertIcon fill={secondaryPink[700]} style={{ width: 24, height: 24 }} />,
      wrapperBackgroundColor: secondaryPink[100],
    },
  };

  return (
    <ConfirmModal
      automaticallyCloseModalOnButtonClick={false}
      isLoading={isLoading}
      width="50%"
      isModalOpen={isOpen}
      setIsModalOpen={setIsOpen}
      title={modalsContent[type].title}
      message={
        <Typography color="primaryDark.500" lineHeight="21px">
          {modalsContent[type].text}
        </Typography>
      }
      confirmButtonText={type === 'delete' ? 'Delete' : 'Close'}
      onConfirm={onConfirm}
      iconProps={{
        icon: modalsContent[type].icon,
        wrapperBackgroundColor: modalsContent[type].wrapperBackgroundColor,
      }}
      showCancelButton={type === 'delete' && !isLoading}
      onCancel={() => setIsOpen(false)}
    />
  );
};

export default DeleteTaskFileModal;
