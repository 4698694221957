import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Stack, Box, IconButton, Typography, ClickAwayListener, Divider } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  CompanyRole,
  EnumProjectMilestoneStatus,
  EnumProjectTaskStatus,
} from 'tdc-web-backend/enums/enums';
import { TaskDto, TaskFileDto, UpdateTaskDto } from 'tdc-web-backend/tasks/schemas';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { AxiosResponse } from 'axios';
import { SubtaskDto, UpdateSubtaskDto } from 'tdc-web-backend/subtasks/schemas';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { ReactComponent as FlagIcon } from '../../../../../assets/icons/layout-icons/FlagIcon.svg';
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/layout-icons/TrashIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/layout-icons/ArrowRight.svg';
import { ReactComponent as CalendarCheckIcon } from '../../../../../assets/icons/project-icons/CalendarCheckIcon.svg';
import { ReactComponent as PencilIcon } from '../../../../../assets/icons/pencil.svg';
import { ReactComponent as LinkIcon } from '../../../../../assets/icons/link-icon.svg';
import { ReactComponent as FileDownloadIcon } from '../../../../../assets/icons/project-icons/FileDownloadIcon.svg';
import { ReactComponent as CheckedIcon } from '../../../../../assets/icons/project-icons/CheckedIcon.svg';
import { secondaryPink, primaryDark, primaryLight } from '../../../../../color';
import CustomButton from '../../../../../components/button/CustomButton';
import CustomDropdown from '../../../../../components/custom-dropdown/CustomDropdown';
import ControlledCheckboxInput from '../../../../../components/custom-inputs/ControlledCheckboxInput';
import ControlledDateInput from '../../../../../components/custom-inputs/ControlledDateInput';
import ControlledTextInputAutoResizeWidth from '../../../../../components/custom-inputs/ControlledTextInputAutoResizeWidth';
import StatusChipField from '../../../../../components/StatusChipField/StatusChipField';
import { mapTaskStatus } from '../../../../../enums';
import EndToEndDetailScreenTypeEnum from '../../../../../utils/enums/end-to-end-detail-screen-type';
import { useRefresh } from '../../../../../utils/hooks/crud-hooks/useRefresh';
import useUpdate from '../../../../../utils/hooks/crud-hooks/useUpdate';
import useDetermineCompanyRoles from '../../../../../utils/hooks/useDetermineCompanyRoles';
import StatusChipDropdown from '../../../../../components/status-chip-dropdown/StatusChipDropdown';
import CustomTextField from '../../CustomTextField/CustomTextField';
import useCreate from '../../../../../utils/hooks/crud-hooks/useCreate';
import { formatDateFns, iconUrlForFile } from '../../../../../utils/helpers';
import UploadAttachment from './components/UploadAttachment';
import DeleteTaskFileModal from './components/DeleteTaskFileModal';
import useDeleteOne from '../../../../../utils/hooks/crud-hooks/useDeleteOne';
import { set } from 'date-fns';

interface RightColumnProps {
  numberOfTasksRows: number | null;
  showRightColumnn: boolean;
  isLessThanLarge: boolean;
  milestoneData: MilestoneDto | undefined;
  taskData: TaskDto;
  isFetchingTaskLoading: boolean;
  isTaskDateEndEditing: boolean;
  setIsTaskDateEndEditing: Dispatch<SetStateAction<boolean>>;
  setShowRightColumn: Dispatch<SetStateAction<boolean>>;
  setIsFlagAndUnflagModalOpen: Dispatch<SetStateAction<boolean>>;
  setEntityType: Dispatch<SetStateAction<EndToEndDetailScreenTypeEnum>>;
  setEntityId: Dispatch<SetStateAction<string | null>>;
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  task?: AxiosResponse;
  getRightColumnRefWidth: (width: number) => void;
}

const RightColumn = ({
  numberOfTasksRows,
  showRightColumnn,
  isLessThanLarge,
  milestoneData,
  taskData,
  isFetchingTaskLoading,
  isTaskDateEndEditing,
  setIsTaskDateEndEditing,
  setShowRightColumn,
  setIsFlagAndUnflagModalOpen,
  setEntityType,
  setEntityId,
  setIsDeleteModalOpen,
  task,
  getRightColumnRefWidth,
}: RightColumnProps) => {
  const params = useParams();
  const refresh = useRefresh();

  const rightColumnRef = useRef<HTMLDivElement>(null);
  const subtaskFormRef = useRef<HTMLDivElement>(null);
  const rightElsubtaskFormRef = useRef<HTMLDivElement>(null);

  const [initialSubtaskFormRefHeight, setInitialSubtaskFormRefHeight] = useState<number | null>(
    null,
  );

  const userCompanyRoles = useDetermineCompanyRoles();
  const isUserBuyer = userCompanyRoles?.includes(CompanyRole.Buyer);

  const [isSubtaskEditingObj, setIsSubtaskEditingObj] = useState<Record<number, boolean>>({});

  const [subtaskNameInputMaxWidth, setSubtaskNameInputMaxWidth] = useState<number | null>(null);

  const isTaskFlagged = taskData?.buyerFlagged || taskData?.sellerFlagged;

  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

  const [resizableTextInputContent, setResizableTextInputContent] = useState<string>('');
  const [resizableTextInputInitialValue, setResizableTextInputInitialValue] = useState<
    string | null
  >(null);

  const [isFileAttachmentModalOpen, setIsFileAttachmentModalOpen] = useState<boolean>(false);
  const [isFileDeleteModalOpen, setIsFileDeleteModalOpen] = useState<boolean>(false);
  const [isDeleteFileErrorModalOpen, setIsDeleteFileErrorModalOpen] = useState<boolean>(false);
  const [selectedFileDelete, setSelectedFileDelete] = useState<TaskFileDto | null>(null);

  const shouldShowButtonToUnflagTask = () => {
    if (isUserBuyer) {
      if (taskData?.buyerFlagged) return true;
      if (taskData?.sellerFlagged) return false;
    }

    if (!isUserBuyer) {
      if (taskData?.buyerFlagged) return false;
      if (taskData?.sellerFlagged) return true;
    }

    return true;
  };

  const shouldShowButtonToUnflagSubtask = (subtaskData: SubtaskDto) => {
    if (isUserBuyer) {
      if (subtaskData?.buyerFlagged) return true;
      if (subtaskData?.sellerFlagged) return false;
    }

    if (!isUserBuyer) {
      if (subtaskData?.buyerFlagged) return false;
      if (subtaskData?.sellerFlagged) return true;
    }

    return true;
  };

  const [isTaskStartDateEditing, setIsTaskStartDateEditing] = useState<boolean>(false);

  // eslint-disable-next-line operator-linebreak
  const shouldDisableButton: boolean =
    milestoneData?.status === EnumProjectMilestoneStatus.UnderReview ||
    milestoneData?.status === EnumProjectMilestoneStatus.Approved ||
    milestoneData?.status === EnumProjectMilestoneStatus.Completed;

  const customDeleteTaskDropdownMenuItemContent = {
    key: 'delete',
    // eslint-disable-next-line max-len
    icon: <TrashIcon fill={primaryDark[500]} style={{ width: 18, height: 18 }} />,
    text: 'Delete',
    disabled: (() => {
      if (shouldDisableButton) {
        return true;
      }

      return false;
    })(),
    onClick: () => {
      setIsDeleteModalOpen(true);
      setEntityType(EndToEndDetailScreenTypeEnum.Task);
      setEntityId(taskData.id);
    },
  };

  const customSubtaskDropdownMenuItemContents = (index: number) => [
    {
      key: 'edit',
      // eslint-disable-next-line max-len
      icon: <PencilIcon fill={primaryDark[500]} style={{ width: 18, height: 18 }} />,
      disabled: (() => {
        if (shouldDisableButton) {
          return true;
        }

        return false;
      })(),
      text: 'Edit',
      onClick: () =>
        setIsSubtaskEditingObj({
          // ...isTaskTitleEditingProp,
          [index]: true,
        }),
    },
    {
      key: 'delete',
      // eslint-disable-next-line max-len
      icon: <TrashIcon fill={primaryDark[500]} style={{ width: 18, height: 18 }} />,
      disabled: (() => {
        if (shouldDisableButton) {
          return true;
        }

        return false;
      })(),
      text: 'Delete',
      onClick: () => {
        setIsDeleteModalOpen(true);
        setEntityType(EndToEndDetailScreenTypeEnum.Subtask);
        setEntityId(addSubtaskGetValues('subtasks')[index].id);
      },
    },
  ];

  const addSubtaskFormMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      subtasks:
        taskData && taskData.subtasks.length > 0
          ? taskData.subtasks.map((subtask: SubtaskDto) => ({
              id: subtask.id,
              status: subtask.status,
              name: subtask.name,
              end: subtask.end,
              buyerFlagged: subtask.buyerFlagged,
              sellerFlagged: subtask.sellerFlagged,
            }))
          : [
              {
                id: '',
                status: false,
                name: '',
                end: new Date(),
              },
            ],
    },
  });

  const {
    control: addSubtaskControl,
    reset,
    getValues: addSubtaskGetValues,
  } = addSubtaskFormMethods;

  const { fields, append, remove, update } = useFieldArray({
    control: addSubtaskControl,
    name: 'subtasks',
  });

  const { mutate: updateTask } = useUpdate<TaskDto, UpdateTaskDto>({ resource: '/tasks' });

  const { mutate: updateSubtask } = useUpdate<SubtaskDto, UpdateSubtaskDto>({
    resource: '/subtasks',
  });

  const { mutate: createSubtask } = useCreate<SubtaskDto, UpdateSubtaskDto>({
    resource: '/subtasks',
  });

  const { mutate: deleteFile, isLoading: isDeleteFileLoading } = useDeleteOne({
    resource: '/tasks/file',
  });

  const editTaskformMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      id: '',
      name: '',
      description: '',
      start: taskData?.start ? taskData.start : new Date(),
      end: new Date(new Date().setDate(new Date().getDate() + 1)),
    },
  });

  const { getValues, setValue: editTaskSetValue } = editTaskformMethods;

  const onEditTaskSubmit = () => {
    updateTask(
      {
        data: {
          name: getValues('name'),
          description: getValues('description'),
          milestone: params?.milestoneId as string,
          start: getValues('start'),
          end: getValues('end'),
        },
        id: getValues('id'),
      },
      {
        onSuccess: () => {
          refresh();

          if (isTaskDateEndEditing) {
            setIsTaskDateEndEditing(false);
          }

          if (isTaskStartDateEditing) {
            setIsTaskStartDateEditing(false);
          }
        },
      },
    );
  };

  const onSaveSubtaskSubmit = (subtask: SubtaskDto) => {
    const taskDataIds = taskData.subtasks.map((item: SubtaskDto) => item.id);

    // edit
    if (taskDataIds.includes(subtask.id)) {
      updateSubtask(
        {
          id: subtask.id,
          data: {
            name: subtask.name,
            end: subtask.end,
            status: subtask.status,
          },
        },
        {
          onSuccess: () => {
            refresh();

            const vals: Record<string, boolean> = {};

            fields.forEach((field, index) => {
              vals[index] = false;
            });

            setIsSubtaskEditingObj(vals);
          },
        },
      );
      // create
    } else {
      createSubtask(
        {
          name: subtask.name,
          end: subtask.end,
          status: subtask.status,
          task: taskData.id,
        },
        {
          onSuccess: () => {
            refresh();

            const vals: Record<string, boolean> = {};

            fields.forEach((field, index) => {
              vals[index] = false;
            });

            setIsSubtaskEditingObj(vals);
          },
        },
      );
    }
  };

  useEffect(() => {
    if (taskData !== undefined) {
      editTaskSetValue('id', taskData.id as string);
      editTaskSetValue('name', taskData.name as string);
      editTaskSetValue('description', taskData.description as string);
      editTaskSetValue('start', taskData.start);
      editTaskSetValue('end', taskData.end);
    } else {
      editTaskSetValue('id', '');
      editTaskSetValue('name', '');
      editTaskSetValue('description', '');
    }
  }, [taskData]);

  useEffect(() => {
    if (taskData && taskData.subtasks.length > 0) {
      reset({
        subtasks: taskData.subtasks.map((subtask: SubtaskDto) => ({
          id: subtask.id,
          status: subtask.status,
          name: subtask.name,
          end: subtask.end,
          buyerFlagged: subtask.buyerFlagged,
          sellerFlagged: subtask.sellerFlagged,
        })),
      });
    } else {
      reset({
        subtasks: [],
      });
    }
  }, [taskData]);

  useEffect(() => {
    if (subtaskFormRef.current) {
      setInitialSubtaskFormRefHeight(subtaskFormRef.current.offsetHeight);
    }
  }, [subtaskFormRef.current]);

  useEffect(() => {
    if (subtaskFormRef.current !== null && rightElsubtaskFormRef.current !== null) {
      setSubtaskNameInputMaxWidth(
        subtaskFormRef.current.offsetWidth - rightElsubtaskFormRef.current.offsetWidth,
      );
    }
  }, [rightElsubtaskFormRef.current, subtaskFormRef.current]);

  // keep track of rightColumnRef width change
  useEffect(() => {
    if (rightColumnRef.current !== null) {
      const observer = new ResizeObserver((entries) => {
        getRightColumnRefWidth(entries[0].contentRect.width);
      });

      observer.observe(rightColumnRef.current);

      return () => rightColumnRef.current && (observer.unobserve(rightColumnRef.current) as any);
    }
  }, []);

  return (
    <>
      <Box
        ref={rightColumnRef}
        sx={{
          display: showRightColumnn || !isLessThanLarge ? 'block' : 'none',
          opacity: numberOfTasksRows !== null && numberOfTasksRows > 0 ? 1 : 0,
          width: { md: '57%', lg: '45%' },
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
          bgcolor: 'common.white',
          boxShadow: '-1px 10px 30px rgba(56, 69, 114, 0.1)',
          position: { md: 'absolute', lg: 'relative' },
          top: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {taskData !== undefined && rightColumnRef.current !== null && !isFetchingTaskLoading && (
          // main column
          <Stack spacing={4} height="100%" sx={{ p: 3, pl: 2 }}>
            {/* task status dropdown & dots dropdown */}
            <Stack spacing={0.75}>
              <Stack direction="row" justifyContent="space-between">
                <Stack spacing={2} direction="row" alignItems="flex-start">
                  {milestoneData?.status !== EnumProjectMilestoneStatus.UnderReview &&
                  milestoneData?.status !== EnumProjectMilestoneStatus.Completed &&
                  milestoneData?.status !== EnumProjectMilestoneStatus.Approved ? (
                    // wrapper component consisting of CustomDropdown
                    // and StatusChipField
                    <StatusChipDropdown<TaskDto>
                      data={taskData}
                      mapToGetTheValueFrom={mapTaskStatus}
                      status={taskData.status}
                      menuElements={EnumProjectTaskStatus}
                      classNames="react-joyride-step-1-task"
                      onMenuElementClick={() => {
                        updateTask(
                          {
                            data: taskData as Partial<UpdateTaskDto>,
                            id: taskData.id as string,
                          },
                          {
                            onSuccess: () => {
                              refresh();
                            },
                          },
                        );
                      }}
                    />
                  ) : (
                    <StatusChipField
                      className="react-joyride-step-1-task"
                      text={taskData.status}
                      sx={{ height: '28px' }}
                    />
                  )}

                  {isTaskFlagged && (
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        bgcolor: 'secondaryPink.100',
                        width: '36px',
                        height: '29px',
                        borderRadius: '6px',
                      }}
                    >
                      <FlagIcon fill={secondaryPink[700]} style={{ width: 16, height: 16 }} />
                    </Stack>
                  )}
                </Stack>

                <Stack spacing={1.5} alignItems="center">
                  {isLessThanLarge && (
                    <IconButton
                      onClick={() => setShowRightColumn(false)}
                      sx={{
                        width: '30px',
                        height: '30px',
                      }}
                    >
                      <CancelRoundedIcon sx={{ color: 'primaryDark.400' }} />
                    </IconButton>
                  )}

                  <CustomDropdown
                    classNames="react-joyride-step-2-task"
                    dropdownOrientation="horizontal"
                    menuItemsContents={
                      shouldShowButtonToUnflagTask()
                        ? [
                            {
                              key: isTaskFlagged ? 'Un-Flag' : 'Flag',
                              // eslint-disable-next-line max-len
                              icon: (
                                <FlagIcon
                                  fill={primaryDark[500]}
                                  style={{ width: 18, height: 18 }}
                                />
                              ),
                              text: isTaskFlagged ? 'Un-Flag' : 'Flag',
                              disabled: (() => {
                                if (
                                  milestoneData?.status === EnumProjectMilestoneStatus.Completed
                                ) {
                                  return true;
                                }

                                return false;
                              })(),
                              onClick: () => {
                                setIsFlagAndUnflagModalOpen(true);
                                setEntityType(EndToEndDetailScreenTypeEnum.Task);
                                setEntityId(taskData.id);
                              },
                            },

                            customDeleteTaskDropdownMenuItemContent,
                          ]
                        : [customDeleteTaskDropdownMenuItemContent]
                    }
                  />
                </Stack>
              </Stack>

              {/* task name */}
              {getValues('name') !== '' && (
                <FormProvider {...editTaskformMethods}>
                  <Box component="form" sx={{ height: '100%' }}>
                    <ControlledTextInputAutoResizeWidth
                      name="name"
                      required
                      // used only to set the initialValue of an input
                      // when you switch task
                      data={task}
                      onSave={onEditTaskSubmit}
                      parentElWidth={rightColumnRef.current.offsetWidth - 40}
                      shouldDisableEditButton={shouldDisableButton}
                      classNames="react-joyride-step-3-task"
                      shouldBoldText
                    />
                  </Box>
                </FormProvider>
              )}
            </Stack>

            {/* task details, calendars, description */}
            <Stack spacing={2}>
              <Typography variant="heading3" color="primaryDark.600" sx={{ ml: 1 }}>
                task details
              </Typography>

              {/* calendars - make this component */}
              <Stack
                spacing={1.5}
                alignItems="center"
                direction="row"
                sx={{ ml: '8px !important' }}
              >
                <IconButton
                  disabled={(() => {
                    if (shouldDisableButton) {
                      return true;
                    }

                    return false;
                  })()}
                  onClick={() => setIsTaskStartDateEditing(true)}
                >
                  <CalendarCheckIcon
                    fill={shouldDisableButton ? primaryDark[300] : primaryDark[500]}
                    style={{ width: 20, height: 20 }}
                  />
                </IconButton>

                {isTaskStartDateEditing ? (
                  <FormProvider {...editTaskformMethods}>
                    <Box component="form" sx={{ ml: '4px !important' }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <ClickAwayListener onClickAway={() => setIsTaskStartDateEditing(false)}>
                          <Box>
                            <ControlledDateInput
                              name="start"
                              label="Start date"
                              required
                              minDate={new Date(milestoneData?.start as Date)}
                              maxDate={new Date(milestoneData?.end as Date)}
                              onChange={() => onEditTaskSubmit()}
                              openDatePicker={isTaskStartDateEditing}
                              RenderInputElement={CustomTextField}
                              inputProps={{ style: { fontSize: '0.875rem' } }}
                            />
                          </Box>
                        </ClickAwayListener>
                      </LocalizationProvider>
                    </Box>
                  </FormProvider>
                ) : (
                  // prevent showing old date (from taskData.start) after updating (changing) date
                  // and THEN updating to the new value;
                  // start property is basically string when it gets data from taskData
                  // or when it gets changed
                  // in which case we want to get its value and then show it
                  typeof getValues()?.start === 'string' && (
                    <Typography color="primaryDark.600" fontWeight={600} ml="2px !important">
                      {formatDateFns(getValues()?.start)}
                    </Typography>
                  )
                )}

                <ArrowRightIcon fill={primaryLight[500]} style={{ width: 16, height: 16 }} />

                <Stack
                  spacing={1.5}
                  direction="row"
                  alignItems="center"
                  sx={{ ml: '5px !important' }}
                >
                  <IconButton
                    disabled={(() => {
                      if (shouldDisableButton) {
                        return true;
                      }

                      return false;
                    })()}
                    onClick={() => setIsTaskDateEndEditing(true)}
                  >
                    <CalendarCheckIcon
                      fill={shouldDisableButton ? primaryDark[300] : primaryDark[500]}
                      style={{ width: 20, height: 20 }}
                    />
                  </IconButton>

                  {isTaskDateEndEditing ? (
                    <FormProvider {...editTaskformMethods}>
                      <Box component="form" sx={{ ml: '4px !important' }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <ClickAwayListener onClickAway={() => setIsTaskDateEndEditing(false)}>
                            <Box>
                              <ControlledDateInput
                                name="end"
                                label="End date"
                                required
                                // minDate={new Date(milestoneData?.start as Date)}
                                // maxDate={new Date(milestoneData?.end as Date)}
                                onChange={() => onEditTaskSubmit()}
                                openDatePicker={isTaskDateEndEditing}
                                RenderInputElement={CustomTextField}
                                inputProps={{ style: { fontSize: '0.875rem' } }}
                              />
                            </Box>
                          </ClickAwayListener>
                        </LocalizationProvider>
                      </Box>
                    </FormProvider>
                  ) : (
                    // prevent showing old date (from taskData.end) after updating (changing) date
                    // and THEN updating to the new value;
                    // start property is basically string when it gets data from taskData
                    // or when it gets changed
                    // in which case we want to get its value and then show it
                    typeof getValues()?.end === 'string' && (
                      <Typography color="primaryDark.600" fontWeight={600} ml="2px !important">
                        {formatDateFns(getValues()?.end)}
                      </Typography>
                    )
                  )}
                </Stack>
              </Stack>

              {/* task description */}
              {getValues('name') !== '' && (
                <FormProvider {...editTaskformMethods}>
                  <Box component="form" sx={{ height: '100%' }}>
                    <ControlledTextInputAutoResizeWidth
                      name="description"
                      placeholder="Add description here"
                      // used only to set the initialValue of an input
                      // when you switch task
                      data={task}
                      onSave={onEditTaskSubmit}
                      showLabelAndSaveButtonInsideInput
                      parentElWidth={rightColumnRef.current.offsetWidth - 50}
                      shouldDisableEditButton={shouldDisableButton}
                    />
                  </Box>
                </FormProvider>
              )}
            </Stack>
            <Divider />

            <Stack spacing={2}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="heading3" color="primaryDark.600" sx={{ ml: 1 }}>
                  files
                </Typography>

                <CustomButton
                  startIcon={<LinkIcon fill={primaryLight[500]} />}
                  className="react-joyride-step-4-task"
                  disabled={(() => {
                    if (shouldDisableButton) {
                      return true;
                    }

                    return false;
                  })()}
                  onClick={() => setIsFileAttachmentModalOpen(true)}
                  variant="primaryText"
                  sx={{
                    '&, &:hover, &:disabled': { textDecoration: 'none' },
                  }}
                >
                  <Typography variant="heading4">upload file</Typography>
                </CustomButton>
              </Stack>
              <Box sx={{ maxHeight: '180px', overflow: 'auto' }}>
                <Stack pl={1} spacing={2}>
                  {taskData?.files.map((file: TaskFileDto) => (
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      sx={{
                        height: '3rem',
                        borderRadius: '0.5rem',
                        backgroundColor: primaryDark[200],
                        padding: '0.5rem',
                      }}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Box
                          component="img"
                          sx={{
                            maxWidth: '2rem',
                            maxHeight: '2rem',
                            objectFit: 'contain',
                            // borderRadius: '4px',
                            p: 0,
                          }}
                          src={(() => iconUrlForFile(file.fileName))()}
                        />
                        <Typography ml={1}>{file.fileName}</Typography>
                        <IconButton sx={{ pointerEvents: 'none' }}>
                          <CheckedIcon height="20px" width="20px" />
                        </IconButton>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <IconButton href={file.file} download>
                          <FileDownloadIcon
                            style={{ height: '1rem', width: '1rem', fill: primaryDark[500] }}
                          />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            setSelectedFileDelete(file);
                            setIsFileDeleteModalOpen(true);
                          }}
                        >
                          <TrashIcon
                            style={{ height: '1rem', width: '1rem', fill: primaryDark[500] }}
                          />
                        </IconButton>
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            </Stack>
            <Divider />
            {/* subtasks */}
            <Stack spacing={2} height="100%">
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="heading3" color="primaryDark.600" sx={{ ml: 1 }}>
                  subtask details
                </Typography>

                <CustomButton
                  startIcon={<AddCircleRoundedIcon />}
                  className="react-joyride-step-4-task"
                  disabled={(() => {
                    if (shouldDisableButton) {
                      return true;
                    }

                    return false;
                  })()}
                  onClick={() => {
                    append({
                      status: false,
                      name: '',
                      end: null,
                    });

                    setIsSubtaskEditingObj({
                      ...isSubtaskEditingObj,
                      [fields.length]: true,
                    });
                  }}
                  variant="primaryText"
                  sx={{
                    '&, &:hover, &:disabled': { textDecoration: 'none' },
                  }}
                >
                  <Typography variant="heading4">add subtask</Typography>
                </CustomButton>
              </Stack>

              {/* subtasks form */}
              <FormProvider {...addSubtaskFormMethods}>
                <Box
                  ref={subtaskFormRef}
                  component="form"
                  sx={{
                    height: '100%',
                    width: '100%',
                    maxHeight: initialSubtaskFormRefHeight,
                    overflowY: 'auto',
                  }}
                >
                  {fields.map((field, index) => (
                    <ClickAwayListener
                      onClickAway={() => {
                        if (isSubtaskEditingObj[index]) {
                          // close subtask name & date inputs (make them unactive i.e.)
                          setIsSubtaskEditingObj({
                            ...isSubtaskEditingObj,
                            [index]: false,
                          });

                          // if input for current doesn't have id, and you click away,
                          // remove that field from array
                          // (subtasks without id are those who did not get created)
                          //
                          if (!('id' in addSubtaskGetValues('subtasks')[index])) {
                            remove(index);
                          }

                          const initialEndDate = taskData.subtasks[index].end;
                          const currentEndDate = addSubtaskGetValues('subtasks')[index].end;

                          // for editing;
                          // if the current input value or end dates are not the same as the initial one,
                          // reset subtask fields with the old (unedited) data
                          if (
                            resizableTextInputContent !== resizableTextInputInitialValue ||
                            currentEndDate !== initialEndDate
                          ) {
                            reset({
                              subtasks: taskData.subtasks.map((subtask: SubtaskDto) => ({
                                id: subtask.id,
                                status: subtask.status,
                                name: subtask.name,
                                end: subtask.end,
                                buyerFlagged: subtask.buyerFlagged,
                                sellerFlagged: subtask.sellerFlagged,
                              })),
                            });
                          }
                        }
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          '&:nth-child(1)': {
                            borderTop: '1px solid #E5E8FF',
                          },
                          minHeight: 57,
                          ml: '8px !important',
                          py: '4px',
                          px: '10px',
                          borderBottom: '1px solid #E5E8FF',
                        }}
                      >
                        <Stack spacing={!isSubtaskEditingObj[index] ? 2 : 5} direction="row">
                          {/* only if it is in editing mode */}
                          <ControlledCheckboxInput
                            disabled={(() => {
                              if (shouldDisableButton) {
                                return true;
                              }

                              return false;
                            })()}
                            // display checkbox only if in edit mode
                            sx={{ display: !isSubtaskEditingObj[index] ? 'flex' : 'none' }}
                            name={`subtasks.${index}.status`}
                            label=""
                            onChange={() => {
                              // when creating a new subtask
                              if (!('id' in addSubtaskGetValues('subtasks')[index])) {
                                update(index, {
                                  ...addSubtaskGetValues('subtasks')[index],
                                  status: addSubtaskGetValues('subtasks')[index].status,
                                });
                                // when editing existing subtask
                              } else {
                                updateSubtask(
                                  {
                                    id: addSubtaskGetValues('subtasks')[index].id,
                                    data: {
                                      name: addSubtaskGetValues('subtasks')[index].name,
                                      status: addSubtaskGetValues('subtasks')[index].status,
                                      end: addSubtaskGetValues('subtasks')[index].end,
                                    },
                                  },
                                  {
                                    onSuccess: () => {
                                      refresh();
                                    },
                                  },
                                );
                              }
                            }}
                          />

                          <Stack direction="row" alignItems="center">
                            <ControlledTextInputAutoResizeWidth
                              // className="react-joyride-step-3-task"
                              required
                              // used to prevent the onClickAway callback
                              // when you click outside of ControlledTextInputAutoResizeWidth
                              disableClickAway
                              // used for scenario when a user is going to edit subtask name/subtask date
                              // and cancels the editing (i.e. clicks outside)
                              getContentAndInitialValue={({ content, initialValue }) => {
                                setResizableTextInputInitialValue(initialValue);
                                setResizableTextInputContent(content);
                              }}
                              // this prop means if the subtask is not created (yet)
                              // and ensures that getContentAndInitialValue prop above is ONLY called
                              // when editing existing values
                              isExistingEntity={!('id' in addSubtaskGetValues('subtasks')[index])}
                              name={`subtasks.${index}.name`}
                              data={addSubtaskGetValues('subtasks')[index]}
                              placeholder="New Subtask"
                              showPencilIcon={false}
                              showSaveButton={false}
                              isTaskTitleEditingProp={isSubtaskEditingObj[index]}
                              sx={{
                                maxWidth: (subtaskNameInputMaxWidth as number) - 120,
                              }}
                              disableExternalSaveButton={(shouldDisable) => {
                                setDisableSaveButton(shouldDisable);
                              }}
                              onSave={() => {}}
                            />

                            {(addSubtaskGetValues('subtasks')[index]?.buyerFlagged ||
                              addSubtaskGetValues('subtasks')[index]?.sellerFlagged) && (
                              <Stack
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  bgcolor: 'secondaryPink.100',
                                  width: '36px',
                                  height: '29px',
                                  borderRadius: '6px',
                                }}
                              >
                                <FlagIcon
                                  fill={secondaryPink[700]}
                                  style={{ width: 16, height: 16 }}
                                />
                              </Stack>
                            )}
                          </Stack>
                        </Stack>

                        <Stack
                          ref={rightElsubtaskFormRef}
                          spacing={3}
                          direction="row"
                          alignItems="center"
                        >
                          {isSubtaskEditingObj[index] ? (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <ControlledDateInput
                                name={`subtasks.${index}.end`}
                                RenderInputElement={CustomTextField}
                                maxDate={new Date(milestoneData?.end as Date)}
                                inputProps={{ style: { fontSize: '0.875rem' } }}
                              />
                            </LocalizationProvider>
                          ) : (
                            <Typography color="primaryDark.600">
                              {addSubtaskGetValues('subtasks')[index].end !== null &&
                                formatDateFns(addSubtaskGetValues('subtasks')[index].end)}
                            </Typography>
                          )}

                          {!isSubtaskEditingObj[index] ? (
                            <CustomDropdown
                              classNames="react-joyride-step-1-subtask"
                              menuItemsContents={
                                shouldShowButtonToUnflagSubtask(
                                  addSubtaskGetValues('subtasks')[index],
                                )
                                  ? [
                                      {
                                        key:
                                          addSubtaskGetValues('subtasks')[index]?.buyerFlagged ||
                                          addSubtaskGetValues('subtasks')[index]?.sellerFlagged
                                            ? 'Un-Flag'
                                            : 'Flag',
                                        icon: (
                                          <FlagIcon
                                            fill={primaryDark[500]}
                                            style={{ width: 18, height: 18 }}
                                          />
                                        ),
                                        text:
                                          addSubtaskGetValues('subtasks')[index]?.buyerFlagged ||
                                          addSubtaskGetValues('subtasks')[index]?.sellerFlagged
                                            ? 'Un-Flag'
                                            : 'Flag',
                                        disabled: (() => {
                                          if (
                                            milestoneData?.status ===
                                            EnumProjectMilestoneStatus.Completed
                                          ) {
                                            return true;
                                          }

                                          return false;
                                        })(),
                                        onClick: () => {
                                          setIsFlagAndUnflagModalOpen(true);
                                          setEntityType(EndToEndDetailScreenTypeEnum.Subtask);
                                          setEntityId(addSubtaskGetValues('subtasks')[index].id);
                                        },
                                      },

                                      ...customSubtaskDropdownMenuItemContents(index),
                                    ]
                                  : [...customSubtaskDropdownMenuItemContents(index)]
                              }
                            />
                          ) : (
                            <Typography
                              component="button"
                              disabled={disableSaveButton}
                              onClick={(e) => {
                                e.preventDefault();
                                onSaveSubtaskSubmit(addSubtaskGetValues('subtasks')[index]);
                              }}
                              variant="heading4"
                              color={disableSaveButton ? 'primaryDark.300' : 'primaryLight.500'}
                              sx={{
                                cursor: 'pointer',
                                border: 'none',
                                bgcolor: 'transparent',
                                mr: '4px',
                              }}
                            >
                              save
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    </ClickAwayListener>
                  ))}
                </Box>
              </FormProvider>
            </Stack>
          </Stack>
        )}
      </Box>

      {isFileAttachmentModalOpen && taskData !== undefined && (
        <UploadAttachment
          isOpen={isFileAttachmentModalOpen}
          setIsOpen={setIsFileAttachmentModalOpen}
          data={taskData as TaskDto}
        />
      )}
      <DeleteTaskFileModal
        type="delete"
        isOpen={isFileDeleteModalOpen}
        setIsOpen={(isOpen) => {
          setSelectedFileDelete(null);
          setIsFileDeleteModalOpen(isOpen);
        }}
        onConfirm={() => {
          deleteFile(
            {
              id: selectedFileDelete?.id as string,
            },
            {
              onSuccess() {
                setSelectedFileDelete(null);
                setIsFileDeleteModalOpen(false);
                refresh();
              },
              onError() {
                setSelectedFileDelete(null);
                setIsFileDeleteModalOpen(false);
                setIsDeleteFileErrorModalOpen(true);
              },
            },
          );
        }}
        isLoading={isDeleteFileLoading}
      />

      <DeleteTaskFileModal
        type="error"
        isOpen={isDeleteFileErrorModalOpen}
        setIsOpen={setIsDeleteFileErrorModalOpen}
        onConfirm={() => {
          setSelectedFileDelete(null);
          setIsDeleteFileErrorModalOpen(false);
        }}
      />
    </>
  );
};

export default RightColumn;
