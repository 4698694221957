import { Dispatch, SetStateAction, useState } from 'react';
import { Box, Typography, LinearProgress, Stack, IconButton, Divider } from '@mui/material';
import { TaskDto } from 'tdc-web-backend/tasks/schemas';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import useUploadFile from '../../../../../../utils/hooks/crud-hooks/useUploadFile';
import CustomModal from '../../../../../../components/modal/CustomModal';
import { useRefresh } from '../../../../../../utils/hooks/crud-hooks/useRefresh';
import { formatBytes, getBase64, iconUrlForFile, truncate } from '../../../../../../utils/helpers';
import { primaryDark, secondaryBlue, secondaryPink } from '../../../../../../color';
import ControlledDropzoneFileInput from '../../../../../../components/custom-inputs/ControlledDropzoneFileInput/ControlledDropzoneFileInput';
import CustomButton from '../../../../../../components/button/CustomButton';
import { ReactComponent as CancelCircleIcon } from '../../../../../../assets/icons/project-icons/CancelCircleIcon.svg';
import { ReactComponent as CheckedIcon } from '../../../../../../assets/icons/project-icons/CheckedIcon.svg';
import { ReactComponent as PaymentIcon } from '../../../../../../assets/icons/project-icons/PaymentIcon.svg';
import { ReactComponent as ErrorAlertIcon } from '../../../../../../assets/icons/layout-icons/ErrorAlertIcon.svg';
import { on } from 'events';
import { FileRejection } from 'react-dropzone';

const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // 10MB in bytes

type UploadAttachmentProps = {
  data: TaskDto;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const UploadAttachment = ({ data, isOpen, setIsOpen }: UploadAttachmentProps) => {
  const [fileInfo, setFileInfo] = useState<{
    name?: string;
    size?: number;
    errorMsg?: string | null;
  } | null>({
    name: '',
    size: 0,
    errorMsg: null,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const refresh = useRefresh();

  const { mutate, uploadProgress } = useUploadFile({
    resource: `/tasks/${data?.id}/upload-file`,
  });
  const form = useForm<{ file: string | null; fileName: string }>({
    defaultValues: {
      file: null,
      fileName: '',
    },
    mode: 'onChange',
  });

  const { getValues, setValue, reset } = form;

  const handleDeleteFile = () => {
    reset({ file: null, fileName: '' });
    setFileInfo({ name: '', size: 0, errorMsg: null });
  };

  const handleConfirm = () => {
    setIsLoading(true);

    mutate(
      {
        file: getValues('file'),
        fileName: getValues('fileName'),
      },
      {
        onError: (err) => {
          const errorMessage = (err as Error).message;
          setFileInfo({ ...fileInfo, errorMsg: errorMessage });
        },
        onSuccess: () => {
          setTimeout(() => {
            setIsOpen(false);
            setIsLoading(false);
            refresh();
          }, 1000);
        },
      },
    );
  };

  const handleOnFileSelect = async (acceptedFiles: File[]) => {
    if (!acceptedFiles || acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];

    setFileInfo({
      ...fileInfo,
      name: file?.name,
      size: file?.size,
      errorMsg: null,
    });

    const base64 = await getBase64(acceptedFiles[0]);

    if (base64) {
      setValue('file', String(base64));
      setValue('fileName', file?.name);
    }
  };

  return (
    <CustomModal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      sx={{ maxWidth: '670px' }}
      title="Upload fileee"
    >
      <Stack spacing={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton
            sx={{
              borderRadius: '4px',
              bgcolor: 'secondaryBlue.100',
              width: '3rem',
              height: '3rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                bgcolor: 'secondaryBlue.100',
              },
            }}
          >
            <PaymentIcon fill={secondaryBlue[700]} />
          </IconButton>
          <Typography variant="heading4" fontWeight={600}>
            Upload file
          </Typography>

          {/* <Stack direction="column" spacing={1}>

          </Stack> */}
        </Stack>

        <Divider />

        <Stack sx={{ mt: 2 }}>
          <Stack spacing={2} mt={2}>
            <Typography variant="body2" color="primaryDark.500">
              Upload file
            </Typography>

            <Stack spacing={2}>
              <FormProvider {...form}>
                <form>
                  <ControlledDropzoneFileInput
                    name="file"
                    onUpload={handleOnFileSelect}
                    dropzoneProps={{
                      accept: '.pdf',
                      validator: (file: File) => {
                        if (file.size > MAX_FILE_SIZE_BYTES) {
                          return {
                            code: 'file-too-large',
                            message: `The file is too large. Please try with a smaller file.`,
                          };
                        }
                        return null;
                      },
                      onDropRejected: (fileRejections: Array<FileRejection>) => {
                        const rejection = fileRejections[0];
                        if (rejection?.errors[0]?.code === 'file-too-large') {
                          setFileInfo({
                            ...fileInfo,
                            name: rejection.file.name,
                            size: rejection.file.size,
                            errorMsg: `The file is too large. Please try with a smaller file. `,
                          });
                        }
                      },
                    }}
                    maxFileSize={MAX_FILE_SIZE_BYTES}
                    sx={{
                      maxHeight: '170px',
                      width: '100%',
                      ...(fileInfo?.errorMsg && {
                        borderColor: 'secondaryPink.500',
                        backgroundColor: 'secondaryPink.50',
                      }),
                    }}
                  />
                </form>
              </FormProvider>

              {getValues('file') && fileInfo !== null && fileInfo.errorMsg === null && (
                <Stack spacing={1} width="100%">
                  <Stack
                    spacing={1}
                    width="100%"
                    height="fit-content"
                    sx={{
                      p: 2,
                      textAlign: 'center',
                      bgcolor: 'secondaryBlue.50',
                      borderRadius: '0.5rem',
                      borderColor: 'secondaryBlue.200',
                      border: '1px solid',
                    }}
                  >
                    <Stack direction="row" justifyContent="space-between" alignItems="stretch">
                      {/* file preview & file name, success icon/error icon, file size, remove button */}
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="left"
                        spacing={1.5}
                      >
                        {/* file preview */}
                        <Box
                          component="img"
                          sx={{
                            maxWidth: '3.5rem',
                            maxHeight: '3.5rem',
                            objectFit: 'contain',
                            borderRadius: '4px',
                            p: 0,
                          }}
                          src={(() => iconUrlForFile(fileInfo?.name as string))()}
                        />

                        {/* file name, success icon/error icon & file size */}
                        <Stack spacing={0.5} alignItems="start">
                          <Stack alignItems="center" direction="row">
                            <Typography variant="body3" color="primaryDark.600" fontWeight={600}>
                              {truncate(fileInfo.name as string, 30)}
                            </Typography>

                            {uploadProgress === 100 && (
                              <IconButton sx={{ height: '10px', pointerEvents: 'none' }}>
                                <CheckedIcon />
                              </IconButton>
                            )}
                          </Stack>
                          <Typography variant="body3" color="primaryDark.400">
                            {formatBytes(fileInfo.size as number)}
                          </Typography>
                        </Stack>
                      </Stack>

                      {/* remove button */}
                      <IconButton
                        onClick={() => handleDeleteFile()}
                        disabled={isLoading}
                        disableRipple
                        disableTouchRipple
                        sx={{
                          alignSelf: 'start',
                          mb: 1,
                          p: 0,
                          pt: '2px',
                        }}
                      >
                        <CancelCircleIcon fill={isLoading ? primaryDark[300] : primaryDark[500]} />
                      </IconButton>
                    </Stack>

                    {isLoading && (
                      <Stack direction="row" width="100%" alignItems="center" spacing={1}>
                        <Box
                          sx={{
                            height: '4px',
                            width: '100%',
                            borderRadius: '1rem',
                          }}
                        >
                          <LinearProgress
                            value={uploadProgress || 0}
                            variant="determinate"
                            color="secondary"
                          />
                        </Box>

                        <Typography variant="heading5" color="primaryDark.500">
                          {uploadProgress}%
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              )}
              {fileInfo?.errorMsg && (
                <Typography variant="body3" color="secondaryPink.500" sx={{ alignSelf: 'start' }}>
                  {fileInfo.errorMsg}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Divider />

        <Stack spacing={2} direction="row" sx={{ justifyContent: 'right' }}>
          <CustomButton
            variant="secondary"
            onClick={() => {
              setIsOpen(false);
              reset({ file: null });
              setFileInfo({ name: '', size: 0, errorMsg: null });
            }}
          >
            Cancel
          </CustomButton>

          <CustomButton onClick={handleConfirm} disabled={isLoading || getValues('file') === null}>
            Confirm
          </CustomButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default UploadAttachment;
